<template>
  <MatchMedia query="(max-width: 840px)" v-slot="{ matches }">
    <div v-if="matches" class="mobileBox">
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Factories
            <span class="num">
              {{ total }}
            </span>
          </div>
        </div>
        <div class="item">
          <div class="tip"></div>
          <router-link to="/factories/create">
            <Button type="primary" icon="md-add">Add Factory</Button>
          </router-link>
        </div>
      </div>
      <div class="filter">
        <div class="search-box" style="width:100%;margin:0">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a member..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>
      </div>
      <ul class="lists" v-if="list.length">
        <li v-for="(item, index) in list" :key="index">
          <h3>
            <span>{{ item.factory_name }}</span>
          </h3>
          <div class="content">
            <div class="con">
              <p class="tit">MAIN CATEGORY</p>
              <p class="name">{{ item.main_product_category }}</p>
            </div>
            <div class="con">
              <p class="tit">LOCATION</p>
              <p class="name">
                {{ item.city }},{{ item.state }},{{ item.country_info.name }}
              </p>
            </div>
          </div>
          <div class="footer">
            <div class="doit" @click="edit(item)">
              <Icon type="md-create" />
              Edit Details
            </div>
            <div class="doit" @click="removeFactory(item)">
              <Icon type="ios-remove-circle" />
              Remove Member
            </div>
          </div>
        </li>
      </ul>
      <p style="padding:100px 0;text-align:center" v-else>No Data</p>
      <p class="loadingLists" v-if="loading"><spin></spin></p>
      <div class="page">
        <Page
          :page-size="limit"
          :total="total"
          show-total
          @on-change="pageChange"
        />
      </div>
    </div>
    <div class="box" v-else>
      <div class="filter">
        <div class="total">
          <div class="tip">
            Total Factories
          </div>
          <div class="num">
            {{ total }}
          </div>
        </div>
        <div class="search-box">
          <div class="input-box">
            <input
              v-on:keyup.enter="handleSearch"
              v-model="filter.search"
              type="text"
              placeholder="Search for a factory..."
            />
          </div>
          <div class="btn" @click="handleSearch">Search</div>
        </div>

        <div class="item">
          <div class="tip"></div>
          <router-link to="/factories/create">
            <Button type="primary" icon="md-add">Add Factory</Button>
          </router-link>
        </div>
      </div>

      <div style="overflow-y:auto">
        <Table
          :loading="loading"
          :columns="columns"
          :data="list"
          style="min-width:500px;"
        >
          <template slot-scope="{ row: item }" slot="location">
            {{ item.city }},{{ item.state }},{{ item.country_info.name }}
          </template>
          <template slot-scope="{ row: item }" slot="factory_name">
            <router-link
              :to="`/factories/${item.id}/detail?fname=${item.factory_name}`"
              style="color:#f57c00"
            >
              <strong>{{ item.factory_name }}</strong>
            </router-link>
          </template>
          <template slot-scope="{ row: item }" slot="action">
            <Button
              size="small"
              icon="md-create"
              @click="edit(item)"
              type="warning"
            ></Button>
            <Divider type="vertical" style="opacity:0" />
            <Button
              size="small"
              icon="md-remove"
              @click="removeFactory(item)"
              type="error"
            ></Button>
          </template>
        </Table>

        <div class="page" style="min-width:500px;">
          <Page
            :page-size="limit"
            :page-size-opts="[5, 10, 15, 20]"
            :total="total"
            show-sizer
            show-elevator
            show-total
            @on-change="pageChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </div>
    </div>
  </MatchMedia>
</template>

<script>
import api from "@/api";
import { MatchMedia } from "vue-component-media-queries";
const { _queryFactoryList, _removeFactory } = api;

export default {
  created() {
    this.queryFactoryList();
  },
  components: {
    MatchMedia
  },
  data() {
    return {
      page: 1,
      limit: 10,
      total: 0,
      list: [],
      loading: false,

      filter: {
        search: ""
      },

      columns: [
        {
          title: "FACTORY NAME",
          slot: "factory_name"
        },
        {
          title: "MAIN PRODUCT CATEGORY",
          key: "main_product_category"
        },
        {
          title: "LOCATION",
          slot: "location"
        },
        {
          title: "Action",
          key: "action",
          width: 150,
          align: "center",
          slot: "action"
        }
      ]
    };
  },

  methods: {
    edit(item) {
      this.$router.push(`/factories/${item.id}/edit`);
    },

    handleSearch() {
      this.page = 1;
      this.queryFactoryList();
    },

    queryFactoryList() {
      this.loading = true;

      _queryFactoryList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          this.loading = false;
          const { data } = response;
          this.list = data.data;
          this.total = data.total;
        })
        .catch(err => {
          this.loading = false;
          console.log(err);
        });
    },

    pageChange(page) {
      this.page = page;
      this.queryFactoryList();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.queryFactoryList();
    },

    removeFactory(item) {
      this.$Modal.confirm({
        loading: true,
        title: "Confirm Delete",
        content: "Are you sure to delete this item?",
        onOk: () => {
          _removeFactory({
            id: item.id
          })
            .then(() => {
              this.$Modal.remove();
              this.$Message.success("success");
              this.queryFactoryList(); // refresh
            })
            .catch(({ message }) => {
              this.$Modal.remove();
              this.$Message.error(message);
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.filter {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  .total {
    color: #333;
    .num {
      font-size: 16px;
      font-weight: bold;
    }
  }
  .search-box {
    // flex: 1;
    flex: none;
    height: 40px;
    display: flex;
    margin: 0 20px;
    .input-box {
      position: relative;
      flex: 1;
      height: 100%;
      background: #fff;
      line-height: initial;
      padding-left: 20px;
      padding-right: 20px;
      border: 1px solid #dfdfdf;
      border-right: none;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        color: #444;
        caret-color: #888;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
      }
    }
    .btn {
      cursor: pointer;
      background: #fff;
      color: @primary-color;
      border: 1px solid @primary-color;
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
    }
  }
}
.page {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
</style>
<style lang="less" scoped>
.mobileBox {
  .loadingLists {
    width: 22px;
    margin: 100px auto;
  }
  .lists {
    list-style: none;
    li {
      margin-bottom: 10px;
      border: 1px solid #f2f2f2;
      border-radius: 4px;
      box-shadow: 0 0 4px #eee;
      h3 {
        font-size: 14px;
        padding: 6px 10px;
        border-bottom: 1px solid #f2f2f2;
        .fr {
          float: right;
        }
      }
      .content {
        display: flex;
        padding: 10px 10px;
        .con {
          flex: 0 0 50%;
          font-size: 12px;
          .tit {
            color: #888;
            padding: 4px 0;
          }
        }
      }
      .footer {
        display: flex;
        font-size: 12px;
        border-top: 1px solid #f2f2f2;
        .doit {
          flex: 0 0 50%;
          text-align: center;
          padding: 6px 0;
          &:first-child {
            color: #ff6600;
          }
          &:last-child {
            color: #ca1f01;
            border-left: 1px solid #f2f2f2;
          }
        }
        .not {
          color: #ccc !important;
        }
      }
    }
  }
}
</style>
